<!--
 * @LastEditors: luo
 * @LastEditTime: 2024-12-02 11:31:58
 * @Description: 
-->
<template>
    <div class="segmented-wrapper">
        <div class="segmented">
            <div class="segmented-item" :class="{ 'segmented-item-active': value === item[rowKey] }"
                v-for="item in options" :key="item[rowKey]" @click="handleClick(item)">
                {{ item.label }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'segmented',
    props: {
        options: {
            type: Array,
            default: () => []
        },
        value: {
            type: String,
            default: ''
        },
        rowKey: {
            type: String,
            default: 'key'
        }
    },
    model: {
        prop: 'value',        // 指定 v-model 要绑定的参数叫什么名字，来自于 props 中定义的参数
        event: 'change',    // 指定要触发的事件名字，将被用于 $emit
    },
    methods: {
        handleClick(item) {
            const key = item[this.rowKey]
            this.$emit('change', key)
        }
    },
}
</script>
<style lang="scss" scoped>
.segmented-wrapper {
    display: inline-block;

    div {
        box-sizing: border-box;
    }

    .segmented {
        display: flex;
        border-radius: 10px;
        border: 1px solid #E9EFF4;
        padding: 4px 6px;
        width: 100%;
        column-gap: 4px;
        &-item {
            border-radius: 8px;
            padding: 6px 12px;
            background-color: #fff;
            font-weight: 600;
            font-size: 14px;
            color: #B5BECE;
            line-height: 16px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            cursor: pointer;
        }

        &-item-active {
            color: #252D3D;
            background: #E9EFF4;
        }
    }
}
</style>
