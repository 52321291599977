var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-wrapper" }, [
    _c("div", { staticClass: "header-line" }, [
      _c("div", { staticClass: "left-part" }, [
        _c(
          "div",
          { staticClass: "back-div" },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/images/go_back_icon.png"),
                alt: "",
              },
            }),
            _c("router-link", { attrs: { to: "/workflow/list" } }, [
              _vm._v("返回"),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "border-line" }),
        _vm._m(0),
      ]),
    ]),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("发布记录")]),
          _vm._l(_vm.publishRecordList, function (item) {
            return _c("RecordBox", {
              key: item.versionId,
              attrs: { data: item },
            })
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c(
            "div",
            { staticClass: "right-header" },
            [
              _c("Segmented", {
                attrs: { options: _vm.segmentedOptions },
                model: {
                  value: _vm.activeKey,
                  callback: function ($$v) {
                    _vm.activeKey = $$v
                  },
                  expression: "activeKey",
                },
              }),
              _c(
                "div",
                { staticClass: "header-btns" },
                [
                  _c("el-button", { staticClass: "default-btn" }, [
                    _vm._v("还原"),
                  ]),
                  _c(
                    "el-button",
                    {
                      staticClass: "default-btn primary-btn",
                      attrs: { type: "primary" },
                    },
                    [_vm._v("创建副本")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "name-div" }, [
      _c("span", { staticClass: "name-label" }, [
        _vm._v("\n                    发布记录\n                "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }