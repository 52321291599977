var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "segmented-wrapper" }, [
    _c(
      "div",
      { staticClass: "segmented" },
      _vm._l(_vm.options, function (item) {
        return _c(
          "div",
          {
            key: item[_vm.rowKey],
            staticClass: "segmented-item",
            class: { "segmented-item-active": _vm.value === item[_vm.rowKey] },
            on: {
              click: function ($event) {
                return _vm.handleClick(item)
              },
            },
          },
          [_vm._v("\n            " + _vm._s(item.label) + "\n        ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }