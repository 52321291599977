<!--
 * @LastEditors: luo
 * @LastEditTime: 2024-12-02 11:39:47
 * @Description: 
-->
<template>
    <div class="page-wrapper">
        <div class="header-line">
            <div class="left-part">
                <div class="back-div">
                    <img src="@/assets/images/go_back_icon.png" alt="" />
                    <router-link to="/workflow/list">返回</router-link>
                </div>
                <div class="border-line"></div>
                <div class="name-div">
                    <span class="name-label">
                        发布记录
                    </span>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="left">
                <div class="title">发布记录</div>
                <!-- https://sit.rlinkiot.com/saascloud/workflow/mode/publishRecord?modeId=xxx -->
                <RecordBox v-for="item in publishRecordList" :key="item.versionId" :data="item" />
            </div>
            <div class="right">
                <div class="right-header">
                    <Segmented :options="segmentedOptions" v-model="activeKey" />
                    <div class="header-btns">
                        <el-button class="default-btn">还原</el-button>
                        <el-button type="primary" class="default-btn primary-btn">创建副本</el-button>
                    </div>
                </div>
                <el-divider></el-divider>
            </div>
        </div>
    </div>
</template>

<script>
import { format } from 'date-fns';
import { apiPublishRecord } from '../../../../api/ruge/workflow/workflow';
import { getMessageError } from '../list/utils';
import RecordBox from './components/recordBox.vue';
import Segmented from './components/segmented.vue';

export default {
    name: 'PublishRecord',
    components: {
        RecordBox,
        Segmented
    },
    data() {
        return {
            loading: false,
            rightLoading: false,
            publishRecordList: [],
            recordDetail: {},
            segmentedOptions: [
                {
                    label: '表单配置',
                    key: 'formConfig'
                },
                {
                    label: '流程配置',
                    key: 'workflowConfig'
                }
            ],
            activeKey: 'formConfig'
        }
    },
    mounted() {
        const modeId = this.$route.query.nodeId;
        if (!modeId) {
            this.$message.error('流程模型ID不存在');
            return;
        }
        this.fetchPublishRecord(modeId);
    },
    methods: {
        fetchPublishRecord(modeId) {
            this.loading = true;
            apiPublishRecord({ modeId })
                .then(res => {
                    this.publishRecordList = res.map(item => {
                        return {
                            ...item,
                            nodeName: item.processName,
                            publishTime: format(new Date(item.lastUpdateDate), 'yyyy-MM-dd HH:mm:ss'),
                            remake: item.publishDescription
                        }
                    });
                    this.loading = false;
                }).catch(err => {
                    this.loading = false;
                    this.$message.error(getMessageError(err));
                })
        },
        fetchPublishHistoryDetail(versionId) {
            const modeId = this.$route.query.nodeId;
            if (!modeId) {
                this.$message.error('流程模型ID不存在');
                return;
            }
            this.rightLoading = true;
            apiPublishHistoryDetail({ modeId, versionId })
                .then(res => {
                    this.rightLoading = false;
                    this.recordDetail = res;
                }).catch(err => {
                    this.rightLoading = false;
                    this.recordDetail = {};
                    this.$message.error(getMessageError(err));
                })
        }
    }
}
</script>
<style lang="scss" scoped>
.page-wrapper {
    height: 100vh;
    display: flex;
    box-sizing: border-box;
    background: #F1F6FD;
    font-size: 14px;
    font-family: 'PingFang SC', 'Microsoft YaHei', sans-serif;
    overflow: auto;
    flex-direction: column;

    .header-line {
        width: 100%;
        height: 70px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;
        border-bottom: 1px solid #e3e8ee;

        .left-part {
            display: flex;
            align-items: center;

            .back-div {
                cursor: pointer;
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 18px;
                color: #2a61ff;

                img {
                    margin-right: 4px;
                    position: relative;
                    top: -1px;
                }

                span {
                    font-weight: 600;
                }
            }

            .border-line {
                width: 1px;
                height: 12px;
                background: #cbdbe9;
                margin: 0 10px;
            }

            .name-div {
                display: flex;
                align-items: center;

                .name-label {
                    display: inline-block;
                    max-width: 375px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-weight: 600;
                    font-size: 18px;
                    color: #252d3d;
                }

                .edit-icon {
                    cursor: pointer;
                    margin-left: 2px;
                }

                .name-input {
                    width: 300px;
                }
            }

            .update-time {
                margin-left: 25px;
                font-weight: 400;
                font-size: 14px;
                color: #5d687c;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        padding: 19px 20px;
        flex: 1;
        column-gap: 20px;

        .left {
            width: 340px;
            background: #FFFFFF;
            border-radius: 10px;
            border: 1px solid #E4E7EB;
            padding: 20px;
            display: flex;
            flex-direction: column;
            overflow: auto;
            row-gap: 12px;

            .title {
                font-weight: 600;
                font-size: 16px;
                color: #252D3D;
                line-height: 18px;
                text-align: left;
                font-style: normal;
                text-transform: none;
            }
        }

        .right {
            flex: 1;
            width: 1040px;
            background: #FFFFFF;
            border-radius: 10px;
            border: 1px solid #E4E7EB;

            .right-header {
                padding: 12px 30px;
                display: flex;
                justify-content: space-between;

                .header-btns {
                    display: flex;
                    column-gap: 20px;

                    .default-btn {
                        border-radius: 8px;
                        border: 1px solid #2A61FF;
                        background: #FFFFFF;
                        color: #2A61FF;
                        padding: 8px 12px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 16px;
                        min-width: 80px;
                        margin-left: 0;
                    }

                    .primary-btn {
                        background: #2A61FF;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }

    ::v-deep {
        .el-divider {
            margin: 0;
        }
    }
}
</style>