var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box" }, [
    _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v(_vm._s(_vm.data.updateUserName))]),
      _c("span", [_vm._v("发布了")]),
      _c("span", [_vm._v(_vm._s(_vm.data.nodeName))]),
    ]),
    _c("div", { staticClass: "date" }, [_vm._v(_vm._s(_vm.data.publishTime))]),
    _c("div", { staticClass: "remark mulLineTruncate" }, [
      _c("span", { staticClass: "start-text" }, [_vm._v("发布备注：")]),
      _c("span", { staticClass: "content" }, [_vm._v(_vm._s(_vm.data.remake))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }