<!--
 * @LastEditors: luo
 * @LastEditTime: 2024-12-02 11:40:26
 * @Description:  
 * @Link: https://sit.rlinkiot.com/saascloud/workflow/mode/publishRecord?modeId=xxx
-->
<template>
    <div class="box">
        <div class="title">
            <span>{{ data.updateUserName }}</span>
            <span>发布了</span>
            <span>{{ data.nodeName }}</span>
        </div>
        <div class="date">{{ data.publishTime }}</div>
        <div class="remark mulLineTruncate">
            <span class="start-text">发布备注：</span>
            <span class="content ">{{ data.remake }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RecordBox',
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    }
}
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    min-height: 154px;;
    display: flex;
    flex-direction: column;
    background: #F9F9F9;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #EEF0F7;
    padding: 16px 16px 20px 16px;

    .title {
        display: flex;
        column-gap: 6px;
        height: 18px;

        font-size: 14px;
        color: #252D3D;
        line-height: 18px;
        text-align: left;
        font-style: normal;
        text-transform: none;

        >span {
            font-weight: 600;
        }

        margin-bottom: 10px;
    }

    .date {
        height: 12px;
        font-weight: 400;
        font-size: 12px;
        color: #B5BECE;
        line-height: 12px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-bottom: 12px;
    }

    .remark {
        .start-text {
            font-weight: 600;
            font-size: 12px;
            color: #252D3D;
            line-height: 14px;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }

        .content {
            font-size: 12px;
            color: #5D687C;
            line-height: 14px;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }


    }

    .mulLineTruncate {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }
}
</style>